<template>
  <footer id="footer" class="container-fluid footer">
    <div class="" @click="handleLogo">
      <h1 class="me-auto">
        <router-link :to="{ path: $route.path }">
          <div class="footer-partner-logo container-fluid">
            <Icon name="by-tradeup" class="img-auto mt-5" />
          </div>
        </router-link>
      </h1>
      <div class="position-fixed bottom-0 end-0 mb-4 me-4">
        <a :href="whatsappLink" target="_blank">
          <Icon
            name="icon-whats-app"
            class="footer-icon img-auto mt-0 icon-whats-app-icon"
          />
        </a>
      </div>
    </div>
  </footer>
</template>  
<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapGetters({
      getPhoneNumber: "getPhoneNumber",
      getShowClaroFieldsComputed: "address/getShowClaroFields",
    }),
    getPhoneNumberComputed() {
      return this.getPhoneNumber;
    },
    whatsappLink() {
      return "https://wa.me/" + this.getPhoneNumber;
    },
    
  },
  methods: {
    handleLogo() {
      if(this.getShowClaroFieldsComputed){
       return this.$router.push("/clarotv");
      }else{
        return this.$router.push("/");
      }
    },
  },
};
</script>