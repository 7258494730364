<template>
  <div class="grid-page">
    <slot name="header"></slot>
    <slot name="adressbar"></slot>
    <div class="main-content">
    <slot name="main"></slot>
    <slot name="footer"></slot>
    </div>
    
  </div>
</template>
<script>
export default {
  name: "HomeContainer",
};
</script>

<style lang="scss" scoped>
.grid-page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "adressbar"
    "main"
    "footer";
}
.main-content {
  grid-area: main;
  overflow-y: auto;
  max-height: 92dvh;
}
</style>

